export default {
  paddingTop: '4rem',
  paddingTopDesktop: '5rem',
  marginTop: '1.5rem',
  linkPadding: '0.1rem 0',
  fontSize: '1.3rem',
  titleFontSize: '1rem',
  fontWeightTitle: 'normal',
  infoPaddingTop: '5rem',
  textDecoration: 'none',
  pTagColor: 'var(--white)',
  columnMarginTop: '2rem',
  footerTextMarginTop: '1.5rem',
  displayWrapper: 'none',
  backgroundColor: 'var(--mbc)',
  linkColor: 'var(--white)',
  textColor: 'var(--white)',
  fontFamily: 'var(--font-family)',
};
