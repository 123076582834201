export default {
  mobileMenuBg: 'var(--white)',
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
  mobileMenuButtonColor: 'var(--white)',
  buttonSvgWidthHeight: '1.5rem',
  backgroundColor: 'var(--white)',
  linkColor: 'var(--white)',
  fontFamily: 'var(--fontFamily)',
  textBgColor: 'var(--white)',
  overlayBackgroundColor: 'var(--white)',
  activeLinkColor: 'var(--white)',
  loginButtonBackgroundColor: 'var(--white)',
  loginButtonTextColor: 'var(--white)',
  loginButtonBorderRadius: '30px',
  phoneNumberIconColor: 'var(--white)',
};
